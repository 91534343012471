<template>
  <div class="main-page">
    <section class="page-section">
      <div
        class="grid justify-content-center p-2 lg:p-0"
        style="min-width: 80%"
      >
        <div
          class="col-12 xl:col-6 mt-5"
          style="
            border-radius: 56px;
            padding: 0.3rem;
            background: linear-gradient(
              rgb(41 40 102) 10%,
              rgba(233, 0, 0, 0) 30%
            );
          "
        >
          <div
            class="flex justify-content-center h-full w-full m-0 py-4 px-4"
            style="
              border-radius: 53px;
              background: linear-gradient(
                180deg,
                var(--surface-50) 38.9%,
                var(--surface-0)
              );
            "
          >
            <div class="grid flex-column align-items-center">
              <div class="col-12 flex align-items-center py-5 border-300">
                <router-link to="/applicants/edit">
                  <div class="grid">
                    <div
                      class="flex justify-content-center align-items-center bg-primary border-round"
                      style="height: 5rem; width: 4.5rem"
                    >
                      <i class="pi pi-fw pi-pencil text-50 text-xl"></i>
                    </div>
                    <div class="col-8 ml-4">
                      <p
                        class="text-900 lg:text-xl text-blue-300 font-medium mb-0"
                      >
                        {{ $t("open_application_form") }}
                      </p>
                      <span class="text-gray-600">{{
                        $t("you_have_not_completed_your_application_form")
                      }}</span>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "incompleteApplicationSvgPage",
  components: {},
};
</script>
