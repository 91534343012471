<template>
  <div class="main-page">
    <section class="page-section mb-3">
      <div class="container">
        <div class="card nice-shadow-16 bg-primary-100">
          <div class="flex justify-content-start align-items-center">
            <div class="mb-2">
              <Button icon="pi pi-arrow-left" class="p-button-sm p-button-rounded px-4" @click="() => {
                $router.go(-1);
              }
                " />
            </div>
            <div class="text-2xl text-primary font-bold mb-2 ml-3">
              {{ $t("application_results") }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="col comp-grid">
            <div class="">
              <div>
                <div class="relative-position" style="min-height: 100px">
                  <template v-if="!loading && item">
                    <div v-if="item.application_status == completed || item.application_status == closed"
                      class="row q-col-gutter-x-md">
                      <div v-if="item.application_status == closed" class="">
                        <closed-application></closed-application>
                      </div>
                      <div v-if="!item.applicant_accepted_programme_id" class="grid">
                        <div class="md:col-12 sm:col-12 comp-grid">
                          <div class="card">
                            <div class="text-lg font-bold mt-1">
                              {{ $t("your_selections") }}
                            </div>
                            <hr />

                            <div v-if="canModifyProgrammes()" class="card bg-green-200">
                              <div class="flex justify-content-between">
                                <div class="text-lg">
                                  You are allowed to modify your programme selections until
                                  Admissions Office starts processing your application. <span
                                    class="text-orange-500">After modification, please refresh page to see changes.</span>
                                  <Button class="p-button-link p-0 my-1 mx-1" label="Click here to modify your Selections"
                                    @click="
                                      this.openPageDialog({
                                        page: 'applicants/programmesdata', url: `/applicants/programmesdata`
                                      }, { persistent: true, closeBtn: true, position: 'bottom', width: '50vw' })" />
                                </div>
                                <div>

                                </div>
                              </div>
                            </div>



                            <div class="my-3">
                              <div class="grid">
                                <div class="col-12">
                                  <DataTable :value="loadProgrammesSelection()" :showGridlines="false" :rowHover="true"
                                    responsiveLayout="stack" breakpoint="960px">
                                    <Column field="programmeName" :header="$t('programme')">
                                      <template #body="slotProps">
                                        <div class=" my-2">
                                          {{ slotProps.data.programmeName }}
                                        </div>
                                        <div v-if="slotProps.data.collaborationWith" class="text-sm  hidden-xs hidden-sm">
                                          <span class="text-purple-400">{{
                                            $t("in_collaboration_with")
                                          }}</span> {{ slotProps.data.collaborationWith }}
                                        </div>
                                      </template>

                                    </Column>
                                    <Column field="offerStatus" :header="$t('status')">
                                      <template #body="slotProps">
                                        <Badge size="medium" style="font-size: 0.8rem" class="px-3 my-2"
                                          :value="getStatus(slotProps.data.offerStatus)" :severity="getStatus(slotProps.data.offerStatus) == accepted
                                            ? 'success'
                                            : getStatus(slotProps.data.offerStatus) == processed
                                              ? 'primary'
                                              : 'warning'
                                            "></Badge>
                                      </template>
                                    </Column>
                                    <Column field="action" :header="$t('action')">
                                      <template #body="slotProps">
                                        <Button :label="$t('accept_offer')"
                                          class="p-button-success p-button-text my-2 p-0" :class="slotProps.data.offerStatus ==
                                            accepted
                                            ? 'text-green-500 font-bold'
                                            : 'text-blue-200'
                                            " style="font-size: 13px" :disabled="slotProps.data.offerStatus ==
    accepted
    ? false
    : true
    " @click="
    confirmAcceptance(
      slotProps.data.programmeId,
      slotProps.data.programmeName
    )
    " />
                                      </template>
                                    </Column>
                                  </DataTable>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else class="grid">
                        <div class="col-12 md:col-8 comp-grid">
                          <div class="card">
                            <div>
                              <!-- <div v-html="item.offer_letter" /> -->
                              <div v-html="item.provisional_admission_letter_data" />
                            </div>
                            <div v-show="!loading">
                              <hr />
                              <div class="flex justify-content-between">
                                <div class="flex justify-content-center flex-grow-0">
                                  <div class="mt-2 mb-2">
                                    <Button @click="(event) =>
                                      $refs.exportMenu.toggle(event)
                                      " :label="$t('download_letter')" class="py-2 px-5"
                                      :title="$t('download_letter')" />
                                    <Menu ref="exportMenu" popup :model="pageExportFormats" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 md:col-4 comp-grid">
                          <div class="card sticky-item">
                            <div class="mb-2">
                              <div class="grid">
                                <div class="col-12">
                                  <div class="text-2xl text-primary">
                                    {{ item.applicant_accepted_institution }}
                                  </div>
                                  <div class="text-sm text-gray-500">
                                    {{
                                      item.applicant_accepted_programme_title
                                    }}
                                  </div>
                                </div>
                                <div v-if="item.applicant_accepted_institution_location
                                    " class="grid align-items-center mx-2">
                                  <Divider />
                                  <div class="col-12">
                                    <div class="text-500 text-sm mb-1">
                                      {{ $t("location") }}
                                    </div>
                                    <div class="font-bold">
                                      {{
                                        item.applicant_accepted_institution_location
                                      }}
                                    </div>
                                  </div>
                                </div>
                                <div v-if="item.applicant_accepted_institution_digital_address
                                    " class="grid align-items-center mx-2">
                                  <Divider />
                                  <div class="col-12">
                                    <div class="text-500 text-sm mb-1">
                                      {{ $t("address") }}
                                    </div>
                                    <div class="font-bold">
                                      {{
                                        item.applicant_accepted_institution_digital_address
                                      }}
                                    </div>
                                  </div>
                                </div>
                                <div v-if="item.applicant_accepted_institution_website_url
                                    " class="grid align-items-center mx-2">
                                  <Divider />
                                  <div class="col-12">
                                    <div class="text-500 text-sm mb-1">
                                      {{ $t("website") }}
                                    </div>
                                    <div class="font-bold">
                                      {{
                                        item.applicant_accepted_institution_website_url
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="">
                      <incomplete-application></incomplete-application>
                    </div>
                  </template>
                  <template v-if="loading">
                    <div class="p-3 text-center">
                      <ProgressSpinner style="width: 50px; height: 50px" />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import incompleteApplication from "../custom/incomplete_application_svg.vue";
import closedApplication from "../custom/closed_application_svg.vue";
import { defineAsyncComponent, ref } from "vue";
import { PageMixin } from "../../mixins/page.js";
import { ViewPageMixin } from "../../mixins/viewpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import { i18n } from "../../services/i18n.js";
export default {
  name: "viewApplicantsPage",
  components: {
    incompleteApplication, closedApplication
  },
  mixins: [PageMixin, ViewPageMixin],
  props: {
    pageName: {
      type: String,
      default: "applicants",
    },
    idName: {
      type: String,
      default: "applicant_id",
    },
    routeName: {
      type: String,
      default: "applicantsresults",
    },
    pagePath: {
      type: String,
      default: "applicants/results",
    },
    apiPath: {
      type: String,
      default: "applicants/results",
    },
  },
  data() {
    return {
      item: {
        default: {},
      },
      undergraduate: "Undergraduate",
      accepted: "Accepted",
      pending: "Pending",
      rejected: "Rejected",
      processed: "Processed",
      approved: "Approved",
      completed: "Completed",
      closed: "Closed",
      yes: "Yes",
      data: [],
      pageExportFormats: [
        {
          label: "Pdf",
          icon: "pi pi-file-pdf text-pink-200",
          command: () => {
            this.exportPageRecords("pdf", "pdf");
          },
        },
      ],
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return this.$t("applicants_details");
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["applicants/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("applicants/setCurrentRecord", value);
      },
    },
  },
  meta() {
    return {
      title: this.pageTitle,
    };
  },
  methods: {
    ...mapActions("applicants", ["fetchRecord", "acceptOffer"]),
    confirmAcceptance(programme_id, programme_name) {
      this.$confirm.require({
        message:
          this.$t("are_you_sure_you_want_to_accept_this_offer") +
          " " +
          this.$t(
            "by_clicking_yes_you_confirm_your_application_programme_choice_as"
          ) +
          " " +
          programme_name,
        header: this.$t("confirmation"),
        icon: "pi pi-info-circle",
        accept: () => {
          this.startProgrammeAcceptance(programme_id);
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    async startProgrammeAcceptance(programme_id) {
      this.saving = true;
      let url = "applicants/acceptoffer";
      let payload = {
        applicant_accepted_programme_id: programme_id,
      };
      let data = { url, payload };
      this.acceptOffer(data).then(
        (response) => {
          this.saving = false;
          this.flashMsg(this.$t("application_has_been_submitted_successfully"));
          this.closeDialogs(); // close page dialog that if opened
          this.load();
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    getStatus(status) {
      if (status == this.accepted) {
        return status;
      } else if (status == this.pending) {
        return status
      } else {
        return this.processed
      }
    },
    loadProgrammesSelection() {
      let selections = []
      if (this.item) {
        if (this.item.first_programme_id) {
          selections.push({
            programmeId:
              this.item.first_programme_id,
            programmeName:
              this.item.first_programme_title,
            offerStatus: !this.item
              .first_programme_admission_status
              ? this.pending
              : this.item
                .first_programme_admission_status,
            collaborationWith: this.item.first_programme_collaboration_with,
          })
        }
        if (this.item.second_programme_id) {
          selections.push({
            programmeId:
              this.item.second_programme_id,
            programmeName:
              this.item.second_programme_title,
            offerStatus: !this.item
              .second_programme_admission_status
              ? this.pending
              : this.item
                .second_programme_admission_status,
            collaborationWith: this.item.second_programme_collaboration_with,
          })
        }
        if (this.item.third_programme_id) {
          selections.push({
            programmeId:
              this.item.third_programme_id,
            programmeName:
              this.item.third_programme_title,
            offerStatus: !this.item
              .third_programme_admission_status
              ? this.pending
              : this.item
                .third_programme_admission_status,
            collaborationWith: this.item.third_programme_collaboration_with,
          })
        }
      }
      return selections

    },
    canModifyProgrammes() {
      console.log("Hello world");
      if (this.item.first_programme_admission_status == this.pending && this.item.second_programme_admission_status == this.pending && this.item.third_programme_admission_status == this.pending) {
        if (this.item.application_type == this.undergraduate && this.item.application_status == this.completed && this.item.is_awaiting_results == this.yes) {
          return true;
        } else {
          return false
        }
      } else {
        return false;
      }
    },
  },
  watch: {
    $route(to, from) {
      //only fetch data when navigated to this page
      if (to.name == this.routeName) {
        //this.load();
      }
    },
  },
  async mounted() { },
  async created() { },
};
</script>
<style scoped>
p {
  margin: 0 0 0.4rem 0;
  line-height: 1.5;
}
</style>